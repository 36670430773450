<template>
    <div>
        <div class="commentItem" v-for="(item, index) of commentList" :key="index">
            <div class="f-c-c">
                <el-image class="wh-80 borderRa-50 mr-20 alignSelf-start" :src="item.userAvatarUrl"></el-image>
                <div class="f-cl f1 info">
                    <div class="f-c-b">
                        <div class=" f-c color-dd fs-15" v-if="CommentType == 'first'">
                            <span class="col-33 fw-bold">{{ item.fcommentUsername
                                }}</span>
                            <span class="ml-30 col-99">{{ item.createDate }}</span>
                            <span class="ml-10 col-99">{{ item.fcommentLocation }}</span>
                        </div>
                        <div v-if="CommentType == 'second'" class=" f-c">{{ item.mcommentUsername }}
                            <span v-if="item.connectMcommentUsername" class="triangle"></span>
                            <span>{{ item.connectMcommentUsername }}</span>
                        </div>
                        <!-- <el-popover placement="bottom-start" trigger="click">
                            <el-button v-if="item.fcommentUserId == userId || item.mcommentUserId == userId"
                                @click="removeItem(item)">删除</el-button>
                            <svg slot="reference" width="16" height="16" viewBox="0 0 24 24"
                                class="ZDI ZDI--Dots24 css-zgte1c" fill="currentColor">
                                <path
                                    d="M5.34 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0ZM13.665 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0ZM21.99 12a1.665 1.665 0 1 1-3.33 0 1.665 1.665 0 0 1 3.33 0Z">
                                </path>
                            </svg>
                        </el-popover> -->
                    </div>
                    <div class="f-c-b">
                        <div class="mt-20 col-66 lh-25" style="width: 80%;">{{
            CommentType == 'first' ?
                item.fcommentConent : item.mcommentConent }}</div>
                        <div style="color: #0363FA;" class="hover-pointer" @click="showInput(index)">回复</div>
                    </div>
                    <div v-if="item.fcomments && showCheck" class="paddingLR-10 paddingTB-15 mt-25 f-c-c hover-pointer"
                        style="background: #EFF4FA;border-radius: 10px;width: 130px;color: #026EF4;"
                        @click="showSecondCommont(item, index)">
                        查看全部{{ item.fcomments }}条回复
                    </div>
                    <div v-show="item.replyShow">
                        <SecondaryComment :list="secondLevelcommentinfoList"
                            :getListInfo="getForumNewsPostSecondaryCommentListInfo" :queryType="queryType"
                            @getDateafter="getDateafter">
                        </SecondaryComment>
                        <Pagination v-if="secondLevelcommentinfoList.length" :total="total" :pageNum.sync="currentPage"
                            :limit.sync="size" :layout="layout" @pagination="fetchData" />
                        <el-empty v-if="secondLevelcommentinfoList.length == 0" description="暂无评论！"></el-empty>

                    </div>
                </div>
            </div>
            <div class="f-c-c" v-show="item.showCommonInput">
                <div class="vueEmoji">
                    <textarea class="textarea" v-model="secondLevelcommentValue" placeholder="善语结善缘，恶语伤人心~"></textarea>
                    <img class="commentBtn" @click="addPostSecondaryComment(item)"
                        src="@/assets/Community/IndustryTrends/commentBtn.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import CommonInput from './commonInput.vue';
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';

export default {
    name: 'CommentItems',
    mixins: [PaginationRetention1],
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        DetailInfo: {},
        // first 一级评论 second 二级
        CommentType: {
            type: String,
            default: ''
        },
        // 是否展示二级列表按钮
        showCheck: {
            type: Boolean,
            default: false
        },
        queryType: {}
    },
    watch: {
        list() {
            this.commentList = this.list;
        }
    },
    components: {
        SecondaryComment: () => import("../components/SecondaryComment.vue")
    },
    data() {
        return {
            userId: this.$getStorage('userId'),
            commentList: [],
            secondLevelcommentValue: '', // 二级评论值
            secondLevelcommentinfoList: [],  // 二级评论列表
            getForumNewsPostSecondaryCommentListInfo: {}
        }
    },
    methods: {
        // 显示评论框
        showInput(idx) {
            this.commentList.forEach((item, index) => {
                if (index != idx) {
                    item.showCommonInput = false
                } else {
                    item.showCommonInput = true
                    window.addEventListener("scroll", this.scrolling);
                }
            })
        },

        scrolling() {
            setTimeout(() => {
                this.commentList.forEach((item) => {
                    item.showCommonInput = false
                })
            }, 1000);
        },

        // 显示二级评论盒子
        showSecondCommontBox(postId, fcommentId, item) {
            this.$emit('showSecondCommontBox', { postId, fcommentId, firstComment: item })
        },

        // 显示二级评论
        showSecondCommont(item, idx) {
            let that = this,
                data = {
                    mcommentUserId: parseInt(that.$store.state.userId),
                    postId: item.postId,
                    connectFcommentId: item.fcommentId,
                    // page: 1,
                    // size: 10
                };
            that.getForumNewsPostSecondaryCommentListInfo = data;
            that.commentList.forEach((item, index) => {
                if (index != idx) {
                    item.replyShow = false;
                } else {
                    item.replyShow = true;

                }
            })
            that.getDate()
        },
        getDateafter(data) {
            this.getForumNewsPostSecondaryCommentListInfo = data;
            this.page = 1;
            this.paging = 1;
            this.getDate();
        },
        getDate() {
            let that = this;
            switch (this.queryType) {
                // 行业动态
                case 'IndustryTrends':
                    that.forumNewsPostSecondaryCommentList(that.paging, that.pagingSize);
                    break;
                // 产品供应
                case 'ProductExchange':
                    that.forumPostSecondaryCommentList(that.paging, that.pagingSize);
                    break;
                // 识料杂谈
                case 'ShiLiaoZaTan':
                    that.forumChatPostSecondaryCommentList(that.paging, that.pagingSize);
                    break;
                
            }

        },
        // 行业动态
        forumNewsPostSecondaryCommentList(page = this.page, size = this.size) {
            let that = this,
                data = that.getForumNewsPostSecondaryCommentListInfo;
            that.$http.forumNewsPostSecondaryCommentList({
                ...data,
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach((item) => {
                        item.showCommonInput = false;
                    })
                    that.secondLevelcommentinfoList = res.data.records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total;
                }
            })
        },
        // 产品交流
        forumPostSecondaryCommentList(page = this.page, size = this.size) {
            let that = this,
                data = that.getForumNewsPostSecondaryCommentListInfo;
            that.$http.forumPostSecondaryCommentList({
                ...data,
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach((item) => {
                        item.showCommonInput = false;
                    })
                    that.secondLevelcommentinfoList = res.data.records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total;
                }
            })
        },

        // 识料杂谈
        forumChatPostSecondaryCommentList(page = this.page, size = this.size) {
            let that = this,
                data = that.getForumNewsPostSecondaryCommentListInfo;
            that.$http.forumChatPostSecondaryCommentList({
                ...data,
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach((item) => {
                        item.showCommonInput = false;
                    })
                    that.secondLevelcommentinfoList = res.data.records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total;
                }
            })
        },


        // 获取移除一级评论参数
        getRemoveParams(data) {
            let that = this, { fcommentUserId, fcommentId, postId } = data;
            return {
                fcommentUserId,
                fcommentId,
                postId,
                token: that.$getStorage('token'),
            }
        },
        //产品交流-- 一级评论模块-- 用户移除评论接口
        removeForumPostComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumPostComment(that.getRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$parent.reload()
                    }
                })
            }).catch(() => { })
        },
        // 获取移除二级评论参数
        getSecondaryRemoveParams(data) {
            let that = this, { mcommentUserId, mcommentId, postId } = data;
            return {
                mcommentUserId,//用户id
                mcommentId,//评论id
                postId,
                token: that.$getStorage('token'),
            }
        },
        //产品交流 -- 一级评论模块-- 用户移除二级评论
        removeForumPostSecondaryComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumPostSecondaryComment(that.getSecondaryRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$emit('uploadSecondaryList')
                    }
                })
            }).catch(() => { })
        },

        // 识料杂谈帖子模块---一级评论模块 -- 用户移除评论接口
        removeForumChatPostComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumChatPostComment(that.getRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$parent.reloadList()
                    }
                })
            }).catch(() => { })
        },
        // 识料杂谈帖子模块---二级评论模块 -- 用户移除评论接口
        removeForumChatPostSecondaryComment(data) {
            let that = this;
            that.$confirm('是否删除该评论？', '提示').then(() => {
                that.$http.removeForumChatPostSecondaryComment(that.getSecondaryRemoveParams(data)).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success', 1500)
                        that.$emit('uploadSecondaryList')
                    }
                })
            }).catch(() => { })
        },


        // 二级评论---------------------------------
        getSecondaryCommentParams(data) {
            let that = this,
                { fcommentLocation, sourceIp } = that.$getStorage('getIpAddress'),
                { postId, fcommentId, fcommentUserId, fcommentUsername } = data.item;
            return {
                mcommentUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                postId,
                connectFcommentId: fcommentId,
                connectMcommentUserId: fcommentUserId,
                connectMcommentUsername: fcommentUsername,
                mcommentConent: data.value,
                mcommentLocation: fcommentLocation,
                sourceIp,
                mobject: '',
            }
        },
        getSecondaryCommentSuccess(res) {
            let that = this;
            if (res.code == 200) {
                that.$common.message(res.msg, 'success')
                if (that.CommentType == 'first') {
                    setTimeout(() => {
                        that.$parent.reload()
                    }, 800);
                } else {
                    that.$emit('secondaryBoxComment')
                }

            }
        },

        // 二级评论发表
        addPostSecondaryComment(item) {
            let that = this;
            if (that.secondLevelcommentValue) {
                let data = {
                    mcommentUserId: parseInt(that.$store.state.userId),
                    postId: item.postId,
                    connectFcommentId: item.fcommentId,
                    connectMcommentUserId: item.fcommentUserId,
                    connectMcommentUsername: item.fcommentUsername,
                    mcommentConent: that.secondLevelcommentValue,
                    mobject: "",
                    mcommentLocation: that.$getStorage('getIpAddress').fcommentLocation,
                    sourceIp: that.$getStorage('getIpAddress').sourceIp,
                };
                switch (that.queryType) {
                    case 'IndustryTrends':
                        // 行业动态回复二级评论
                        that.addForumNewsPostSecondaryComment(data);
                        break;
                    case 'ProductExchange':
                        // 行业动态回复二级评论
                        that.addForumPostSecondaryComment(data);
                        break;
                    case 'ShiLiaoZaTan':
                        // 行业动态回复二级评论
                        that.addForumChatPostSecondaryComment(data);
                        break;
                }


            } else {
                that.$common.message('请输入评论！', 'error')
            }
        },

        // 行业动态回复二级评论
        addForumNewsPostSecondaryComment(data) {
            this.$http.addForumNewsPostSecondaryComment(data).then(res => {
                if (res.code == 200) {
                    this.$common.message(res.msg, 'success');
                    this.$emit('forumNewsPostCommentList')
                } else {
                    this.$common.message(res.msg, 'error')
                }
            })
        },
        // 产品供应回复二级评论
        addForumPostSecondaryComment(data) {
            this.$http.addForumPostSecondaryComment(data).then(res => {
                if (res.code == 200) {
                    this.$common.message(res.msg, 'success');
                    this.$emit('forumNewsPostCommentList')
                } else {
                    this.$common.message(res.msg, 'error')
                }
            })
        },
        // 识料杂谈回复二级评论
        addForumChatPostSecondaryComment(data) {
            this.$http.addForumChatPostSecondaryComment(data).then(res => {
                if (res.code == 200) {
                    this.$common.message(res.msg, 'success');
                    this.$emit('forumNewsPostCommentList')
                } else {
                    this.$common.message(res.msg, 'error')
                }
            })
        },

    }
}

</script>
<style lang='less' scoped>
.commentItem {
    border-top: 1px solid #F7F7F7;
    padding: 30px 0;

    .info {
        min-height: 80px;
        font-size: 16px;
    }

    .vueEmoji {
        margin-top: 10px;
        position: relative;

        .textarea {
            width: 800px;
            height: 80px;
            resize: none;
            font-size: 18px;
            padding: 30px 20px 50px 30px;
            border-radius: 10px;
            border: 1px solid #dfdfdf;
        }

        .commentBtn {
            // position: relative;
            position: absolute;
            right: 40px;
            bottom: 20px;
            cursor: pointer;

        }
    }
}
</style>
